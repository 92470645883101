import React from 'react'
import FollowUpImg from '../../../assets/Followup-1.jpg.png'
import './UpOrder.css'
import { Link } from 'react-router-dom'

const UpOrder = () => {
    return (
        <div id='UpOrder'><div className='About'>
            <div className='about__img'>
                <img src={FollowUpImg} alt="" />
            </div>
            <div className='about__content'>
                <h2>تابع طلبك</h2>
                <h3>تابع حالة طلبك بسهولة عبر إدخال رقم الطلب. سنوفر لك جميع التحديثات علي حالة الطلب حتى التسليم
                    <br />
                    <small> (برجاء التوجة لصفحة الطلب وإدخال رقم الطلب لمتابعه تحديثات الطلب) </small>
                </h3>

                <Link className='G-btn G-btnH' style={{ width: "10rem", textAlign: "center" }} to="/FollowUp"> تابع طلبك</Link>
            </div>
        </div >
        </div>
    )
}

export default UpOrder