import React, { useEffect } from 'react'
import './AboutPage.css'
import aboutImg from '../../assets/sev.jpg'
import aboutImg1 from '../../assets/sevP-1.jpg'
import aboutImg2 from '../../assets/sevP-2.jpg'
import aboutImg3 from '../../assets/sevP-3.jpg'
import aboutImg4 from '../../assets/sevP-4.jpg'
import NavPage from '../../components/HomeComp/Nav/PageNav'
import FooterPage from '../../components/HomeComp/Footer/FooterPage'
import Contact from '../../components/HomeComp/Contact/Contact'
import { Helmet } from 'react-helmet'

const AboutPage = () => {
    useEffect(() => {

        window.scrollTo(0, 0);
    }, []);

    return (

        <div className='Aboutpage'>
            <Helmet>
                <meta name="description" content="الوجهة الأولى لخدمات الصيانة المنزلية المتكاملة. نحن فريق من الخبراء المتخصصين في تقديم حلول شاملة ومبتكرة لصيانة المنازل، بهدف ضمان راحتك وسلامة منزلك" />
                <link rel="apple-touch-icon" href="https://norhomecare.com/logo192.png" />
                <link rel="manifest" href="https://norhomecare.com/manifest.json" />

                <title>نور لخدمات الصيانة المنزليه - من نحن</title>
                <meta name="author" content="Dev:Mohamed Metwally Design:Basiony Mamdoh" />

                <meta property="og:title" content="نور لخدمات الصيانة المنزليه - من نحن" />
                <meta property="og:description"
                    content="الوجهة الأولى لخدمات الصيانة المنزلية المتكاملة. نحن فريق من الخبراء المتخصصين في تقديم حلول شاملة ومبتكرة لصيانة المنازل، بهدف ضمان راحتك وسلامة منزلك" />
                <meta property="og:image" content="https://norhomecare.com/Home.jpg" />
                <meta property="og:url" content="https://norhomecare.com/aboutus" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://norhomecare.com/aboutus" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="نور لخدمات الصيانة المنزليه - من نحن" />
                <meta name="twitter:description"
                    content="الوجهة الأولى لخدمات الصيانة المنزلية المتكاملة. نحن فريق من الخبراء المتخصصين في تقديم حلول شاملة ومبتكرة لصيانة المنازل، بهدف ضمان راحتك وسلامة منزلك" />
                <meta name="twitter:image" content="https://norhomecare.com/Home.jpg" />

                <meta name="keywords"
                    content="الوجهة, الأولى, لخدمات, الصيانة, المنزلية, المتكاملة, نحن, فريق, من, الخبراء, المتخصصين, في, تقديم, حلول, شاملة, ومبتكرة, لصيانة, المنازل, بهدف, ضمان, راحتك, وسلامة, منزلك" />
            </Helmet>
            <NavPage />
            <div id='Aboutpage'>
                <div className='About'>
                    <div className='about__img'>
                        <img src={aboutImg} alt="" />
                    </div>
                    <div className='about__content'>
                        <h1>من نحن </h1>
                        <h3>مرحبًا بكم في نور - NOR، الوجهة الأولى لخدمات الصيانة المنزلية المتكاملة. نحن فريق من الخبراء المتخصصين في تقديم حلول شاملة ومبتكرة لصيانة المنازل، بهدف ضمان راحتك وسلامة منزلك. تأسس نور - NOR بناءً على رؤية واضحة لتوفير خدمات صيانة موثوقة وسريعة، تلبي احتياجات كل منزل وتضمن استمرارية تشغيل جميع الأنظمة بكفاءة.
                        </h3>
                    </div>
                </div>
                <div className='V-line'></div>
                <div className='About'>
                    <div className='about__img'>
                        <img src={aboutImg1} alt="" />
                    </div>
                    <div className='about__content'>
                        <h2>مهمتنا</h2>
                        <h3>في نور - NOR، نؤمن أن المنزل هو المكان الذي يجب أن تشعر فيه بالراحة والأمان. لذلك، نلتزم بتقديم خدمات صيانة عالية الجودة تشمل جميع جوانب المنزل، من إصلاح الكهرباء والسباكة إلى أنظمة التكييف والتبريد. هدفنا هو ضمان راحة عملائنا من خلال خدمات متميزة وفريق محترف يسعى دائمًا لتقديم الأفضل.</h3>

                    </div>
                </div>
                <div className='V-line'></div>
                <div className='About'>
                    <div className='about__img'>
                        <img src={aboutImg2} alt="" />
                    </div>
                    <div className='about__content'>
                        <h2>رؤيتنا</h2>
                        <h3>نسعى في نور - NOR إلى أن نكون الشركة الرائدة في مجال الصيانة المنزلية على مستوى المنطقة. رؤيتنا هي تقديم خدمات مخصصة تعتمد على احتياجات كل عميل، مع التركيز على الجودة والاحترافية. نحن نعمل باستمرار على تطوير خدماتنا ومواكبة أحدث التقنيات لضمان تلبية توقعات عملائنا.</h3>


                    </div>
                </div >

                <div className='V-line'></div>
                <div className='About'>
                    <div className='about__img'>
                        <img src={aboutImg3} alt="" />
                    </div>
                    <div className='about__content'>
                        <h2>قيمنا</h2>
                        <ul>
                            <li><strong>الجودة:</strong> <span>نلتزم بتقديم أفضل الخدمات بأعلى معايير الجودة</span></li>
                            <li><strong>الموثوقية:</strong> <span>نعتمد على فريق من الخبراء لضمان موثوقية كل عملية صيانة نقوم بها</span></li>
                            <li><strong>الشفافية:</strong> <span>نحرص على تقديم خدماتنا بأسعار تنافسية وشفافية تامة مع عملائنا</span></li>
                            <li><strong>الابتكار:</strong> <span>نبحث دائمًا عن الحلول الأكثر كفاءة وتطورًا لضمان تلبية جميع احتياجات الصيانة</span></li>
                        </ul>

                    </div>
                </div >
                <div className='V-line'></div>
                <div className='About'>
                    <div className='about__img'>
                        <img src={aboutImg4} alt="" />
                    </div>
                    <div className='about__content'>
                        <h2>فريقنا</h2>
                        <h3>يتألف فريق نور - NOR من فنيين محترفين ومعتمدين في مجالات الكهرباء، السباكة، التكييف، والتبريد. نؤمن بأن الخبرة والمعرفة هما المفتاح لضمان تقديم خدمة استثنائية. لهذا السبب، نعمل على تدريب فريقنا بشكل مستمر للتأكد من مواكبة أحدث التقنيات والمعايير في عالم الصيانة.</h3>

                    </div>
                </div >
            </div>
            <Contact />
            <FooterPage />
        </div >
    )
}

export default AboutPage