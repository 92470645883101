import React from 'react'
import './ShowCase.css'
import { Link as LinkS } from 'react-scroll'
import caseImg from '../../../assets/showcase.png'
import secEnd from '../../../assets/secEnd.png'

const ShowCase = () => {
    return (
        <div id='Showcase' className='showcase'>
            <div className='showcase__container'>
                <div className='showcase__img'>
                    <img src={caseImg} alt="img" />
                </div>
                <div className="showcase__content">
                    <h2><span style={{ color: "black" }}>في نور - NOR</span>
                        <br />
                        نلتزم بجعل كل زيارة صيانة تجربة ممتازة لك. من خلال فريقنا الخبير، سنحرص على أن يكون منزلك دائمًا في أفضل حالاته، مع خدمات موثوقة وسريعة.
                    </h2>
                    <div >
                        <LinkS className='G-btn' to="Serv">أحجز الاّن</LinkS>
                    </div>
                </div>
            </div>
            <img className='secEnd' src={secEnd} alt="" />
        </div>
    )
}

export default ShowCase