import React from 'react'
import './ServicesSec.css'
import { GiElectricalResistance } from "react-icons/gi";
import { MdOutlinePlumbing } from "react-icons/md";
import { LuAirVent } from "react-icons/lu";



import { Link } from 'react-router-dom';


const ServData = [
    {
        id: 1,
        title: "صيانة كهربائية شاملة",
        icon: < GiElectricalResistance />
    },
    {
        id: 2,
        title: "صيانة وتنظيف أنظمة التبريد",
        icon: < LuAirVent />

    },
    {
        id: 3,
        title: "إصلاحات السباكة الفورية",
        icon: <MdOutlinePlumbing />
    }
]


const ServicesSec = () => {
    return (
        <div id='Serv'>
            <div className='Serv'>
                <h2>الخدمات</h2>
                <div className="services-sec">
                    {ServData.map((card) => (
                        <div className='srev-card' key={card.id}>
                            <div className='serv-card-top'>
                                <i>{card.icon}</i>
                                <h4>{card.title}</h4>
                                <p>(يتم تحديد سعر الخدمة بعد زيارة الفني )</p>
                                <div className='triangle'></div>
                            </div>
                            <div className='serv-card-bot'>
                                <Link to={`/order/${card.title}/verify`}>
                                    إحجز الآن
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ServicesSec