import React, { useEffect, useState, useRef } from 'react';
import './OrderDetails.css';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../Loading/Loading';
import prtTemp from '../../../assets/Print-Form_pages.png';

const OrderDetails = () => {
    const location = useLocation();
    const serviceId = location.state?.serviceId;
    const [orderData, setOrderData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const printRef = useRef(); // Ref for order details content

    useEffect(() => {
        const fetchOrderData = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/service/${serviceId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setOrderData(response.data.data || response.data);
                setLoading(false);
            } catch (err) {
                setError(err.response ? err.response.data.message : err.message);
                setLoading(false);
            }
        };

        if (serviceId) {
            fetchOrderData();
        } else {
            setLoading(false);
            setError('Invalid service ID');
        }
    }, [serviceId]);

    // Function to handle printing directly
    const handlePrint = () => {
        const printContent = printRef.current.innerHTML;

        // Create a print style element
        const style = `
            @media print {
            @page {
                background-color: #fdfdfd;
                margin: 0 0rem !important; /* Remove all margins */
                padding: 0 !important; /* Remove all padding */
                size: A4;
            }
            body {
                background-color: #fdfdfd;
                margin: 0 !important; /* Remove all margins */
                padding: 0 !important; /* Remove all padding */
                width: 100%; /* A4 width at 72 DPI */
                height: 100%; /* A4 height at 72 DPI */
                overflow: hidden; /* Prevent overflow */
            }
            button {
                display: none; /* Hide the print button during printing */
            }
            img {
                position: absolute;
                margin: 0 !important;
                padding: 0 !important;
                max-width: 100%; /* Ensure the image fits within the print area */
                height: auto; /* Maintain the aspect ratio */
            }
    
            .ord-details {
                position: absolute;
                width: 2480px;
                margin-top: 12.8rem;
                margin-right: 2rem;
            }
    
            .prtItem7 {
                bottom: -5.55rem;
                right: 1.2rem;
                position: relative;
    
                p {
                    overflow: hidden;
                    height: auto;
                    max-height: 5rem;
                    width: 16rem;
                }
            }
        }`;

        // Create a new style element and append it to the document head
        const styleElement = document.createElement('style');
        const originalTitle = document.title;
        styleElement.innerHTML = style;
        document.head.appendChild(styleElement);

        // Open the print dialog
        const originalContent = document.body.innerHTML; // Store the original content
        document.body.innerHTML = printContent; // Replace the body content with the print content
        document.title = `${orderData.type} | ${orderData.fullName}`; // Set the title
        window.print(); // Trigger the print dialog
        document.body.innerHTML = originalContent; // Restore the original content after printing
        document.title = originalTitle; // Restore original title
        window.location.reload();
    };

    if (loading) return <Loading />;
    if (error) return <p>Error: {error}</p>;
    if (!orderData) return <div>No order data available.</div>;
    return (
        <div id='ord-details'>
            <div className='ord-details-table ord-table-1'>
                <button className='G-btn' onClick={handlePrint} style={{ margin: '20px 0' }}>
                    طباعة التفاصيل
                </button>
                <br />
                <div className='prtForm' ref={printRef}>
                    <img src={prtTemp} alt="img" />
                    <div className='ord-details' dir="rtl">
                        <div className='ord-details2' dir="rtl">
                            <p style={{ marginBottom: "0.2rem" }}><strong>الخدمة:</strong> {orderData.type}</p>

                            <div className='prtItems-container'>
                                <div className='prtItems'>
                                    <p className='prtItem prtItem11'><strong>تاريخ الإنشاء:</strong> {orderData.createdAt ? new Date(orderData.createdAt).toLocaleString() : 'غير متوفر'}</p>
                                    <p className='prtItem'><strong>كود الطلب:</strong> {orderData.serviceCode}</p>
                                    <p className='prtItem'><strong>العامل:</strong> _________________</p>
                                    <p className='prtItem'><strong>رقم جوال العامل:</strong> _________________</p>
                                </div>
                                <div className='prtItems'>
                                    <p className='prtItem prtItem1'><strong>الاسم:</strong> {orderData.fullName}</p>
                                    <p className='prtItem prtItem2'><strong>رقم الهاتف:</strong> <span dir="ltr">{orderData.phoneNumber}</span></p>
                                    <p className='prtItem prtItem1'><strong>الحي:</strong> {orderData.area}</p>
                                    <p className='prtItem prtItem4'><strong>العنوان:</strong> {orderData.addressLineOne}</p>
                                </div>
                            </div>
                            <div className='prtItem7'>
                                <p> {orderData.userNote}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br />

            <div className='ord-details-table ord-table-2' dir='rtl' style={{ marginTop: "1rem" }}>
                <h4>جميع تفاصيل الطلب</h4>

                <div className='prtItems-container'>
                    <div className='prtItems'>
                        <p className='prtItem'><strong>الخدمة:</strong> {orderData.type}</p>
                        <p className='prtItem prtItem11'><strong>تاريخ الإنشاء:</strong> {orderData.createdAt ? new Date(orderData.createdAt).toLocaleString() : 'غير متوفر'}</p>
                        <p className='prtItem'><strong>كود الطلب:</strong> {orderData.serviceCode}</p>
                        <p className='prtItem prtItem3'><strong>البريد الإلكتروني:</strong> {orderData.email}</p>
                        <p className='prtItem prtItem5'><strong>تاريخ الزيارة:</strong> {orderData.periodDate}</p>

                    </div>
                    <div className='prtItems'>
                        <p className='prtItem prtItem1'><strong>الاسم:</strong> {orderData.fullName}</p>
                        <p className='prtItem prtItem2'><strong>رقم الهاتف:</strong> <span dir="ltr"> {orderData.phoneNumber}</span></p>
                        <p className='prtItem prtItem1'><strong>الحي:</strong> {orderData.area}</p>
                        <p className='prtItem prtItem4'><strong>العنوان:</strong> {orderData.addressLineOne}</p>
                        <p className='prtItem prtItem6'><strong>وقت الزيارة:</strong> {orderData.periodFullTime}</p>

                    </div>
                </div>
                <br />
                <p className='prtItem '><strong>ملاحظة العميل:</strong> {orderData.userNote}</p>
                <p className='prtItem prtItem8'><strong>الحالة:</strong> {orderData.message}</p>
                <p className='prtItem prtItem9'><strong>ملاحظة الإدارة:</strong> {orderData.adminNoteOne || 'لا يوجد'}</p>
                <p className='prtItem prtItem10'><strong>ملاحظة الإدارة 2:</strong> {orderData.adminNoteTwo || 'لا يوجد'}</p>
            </div>
        </div>
    );
};

export default OrderDetails;
