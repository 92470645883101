import React, { useEffect, useState } from 'react';
import './FollowUp.css';
import FollowUpImg from '../../assets/Followup-1.jpg.png';
import NavPage from '../../components/HomeComp/Nav/PageNav';
import Contact from '../../components/HomeComp/Contact/Contact';
import FooterPage from '../../components/HomeComp/Footer/FooterPage';
import axios from 'axios';
import Loading from '../../components/Loading/Loading';
import { Link } from 'react-router-dom';
import PageTracingVF from '../../components/TrackerFV/TrackerFV';
import { ImCancelCircle } from "react-icons/im";



const FollowUp = () => {
    const [Ordernum, setOrdernum] = useState(''); // Order number input state
    const [orderDetails, setOrderDetails] = useState(null); // To store fetched order details
    const [error, setError] = useState(null); // To store any error from the request
    const [loading, setLoading] = useState(false); // To indicate loading state
    const [dataAvailable, setDataAvailable] = useState(false);


    const orderMessages = {
        received: "تم إستلام الطلب: نود إبلاغكم بأن خدمتكم حاليًا قيد المراجعة. سوف نقوم بالعمل عليها في اقرب وقت.",
        inProgress: "ﻃﻠﺒﻚ ﻗﻴﺪ اﻟﺘﻨﻔﻴﺬ ﺣﺎﻟﻴًﺎ. ﻧﺤﻦ ﻧﻌﻤﻞ ﻋﻠﻰ ﺗﻠﺒﻴﺔ اﺣﺘﻴﺎﺟﺎﺗﻚ ﻓﻲ أسرع وﻗﺖ ﻣﻤﻜﻦ",
        technicianOnTheWay: "قيد التنفيذ: الفني في طريقه إلى موقعك الآن. شكرًا لتعاونك",
        awaitingConfirmation: [
            "قيد التنفيذ: في انتظار تأكيد الصيانة من قبلكم. يرجى تأكيد الطلب عن طريق الاتصال بخدمة العملاء - نور - لنتمكن من خدمتك. شكرًا لاختيارك نور",
            "قيد التنفيذ: تم تأكيد طلب الصيانة وسيبدأ العمل على الفور. شكرًا لتعاونك."
        ],
        completed: "ﺗﻢ اﻧﺘﻬﺎء ﻣﻦ اﻟﺼﻴﺎﻧﺔ ﺑﻨﺠﺎح ﻧﻘﺪر ﺛﻘﺘﻚ ﻓﻲ ﻧﻮر - NOR"
    };

    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);


    const handleInputChange = (e) => {
        setOrdernum(e.target.value);
    };

    const handleOrderCheck = async () => {
        if (!Ordernum) {
            setError('يرجى إدخال رقم الطلب');
            return;
        }
        setError(null); // Clear previous error
        setLoading(true); // Start loading

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/services/service/inquire/${Ordernum}`);
            setOrderDetails(response.data.data); // Accessing the data property in the response
            setError(null); // Clear error if any
        } catch (err) {
            setError('برجاء التأكد من رقم الطلب والمحاولة مجدداً.');
            setOrderDetails(null); // Clear previous details if there's an error
        }

        finally {
            setLoading(false); // Stop loading
        }

    };

    setTimeout(() => {
        setDataAvailable(true); // Simulate data being available after fetching
        setLoading(false);

        // Scroll to the order details if data is available
        if (dataAvailable) {
            const orderDetailsElement = document.getElementById('order-details');
            if (orderDetailsElement) {
                orderDetailsElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, 50);



    return (
        <div style={{ position: 'relative' }}>
            <NavPage />
            <br />
            <br />

            <div id="FollowUp" >
                <div className="About">
                    <div className="about__img">
                        <img src={FollowUpImg} alt="" />
                    </div>
                    <div className="about__content">
                        <h2>تابع طلبك</h2>
                        <h3>
                            تابع حالة طلبك بسهولة عبر إدخال رقم الطلب. سنوفر لك جميع التحديثات علي حالة الطلب حتى التسليم
                            <br />
                            <small>(برجاء أستخدام رقم الطلب المرسل بالبريد للمتابعة)</small>
                        </h3>
                        <input
                            style={{ margin: "0.5rem 0", height: "44px" }}
                            className="cust-input"
                            type="text"
                            placeholder="أدخل رقم الطلب الخاص بك"
                            value={Ordernum}
                            onChange={handleInputChange}
                        />

                        {error && <p style={{ color: "red", margin: "0.5rem 0" }} className="error"><small>{error}</small></p>}

                        <button className='G-btn' style={{ margin: "0.5rem 0", width: "8rem", padding: "0.5rem", borderRadius: "5px" }} onClick={handleOrderCheck} disabled={loading}>
                            {loading ? 'جارٍ التحميل...' : 'تحقق من الطلب'}
                        </button>
                        <br />
                        {loading ? <Loading /> : ''}
                    </div>
                </div>

                {/* Display order details only if Ordernum is provided */}
                {Ordernum && orderDetails && orderDetails.message && (() => {

                    const isCanceled = orderDetails.message.includes("ﺗﻢ اﻟﻐﺎء ﻃﻠﺒﻚ");
                    const stages = [
                        {
                            label: 'إستلام الطلب',
                            current: orderDetails.message?.includes(orderMessages.received),
                            completed: !orderDetails.message?.includes(orderMessages.received)
                        },
                        {
                            label: 'قيد التنفيذ',
                            current: orderDetails.message?.includes(orderMessages.inProgress),
                            completed: orderDetails.message?.includes(orderMessages.technicianOnTheWay) || orderMessages.awaitingConfirmation.some(msg => orderDetails.message?.includes(msg)) || orderDetails.message?.includes(orderMessages.completed)
                        },
                        {
                            label: 'الفني بالطريق',
                            current: orderDetails.message?.includes(orderMessages.technicianOnTheWay),
                            completed: orderMessages.awaitingConfirmation.some(msg => orderDetails.message?.includes(msg)) || orderDetails.message?.includes(orderMessages.completed)
                        },
                        {
                            label: 'صيانة',
                            current: orderMessages.awaitingConfirmation.some(msg => orderDetails.message?.includes(msg)),
                            completed: orderDetails.message?.includes(orderMessages.completed)
                        },
                        {
                            label: 'تم',
                            current: orderDetails.message?.includes(orderMessages.completed),
                            completed: orderDetails.message?.includes(orderMessages.completed)
                        }

                    ];

                    return (


                        <div id='order-details' className="order-details" dir='rtl'>
                            {isCanceled ? (
                                <div className='canceled-container'>
                                    <div className='canceled'><ImCancelCircle /></div>
                                    <p>تم الغاء الطلب برجاء  <Link to="/contact">التواصل مع الدعم</Link></p>
                                </div>
                            ) : (
                                <PageTracingVF stages={stages} currentStageIndex={stages.findIndex(stage => stage.completed)} />
                            )}


                            <div className='Ord-status'>
                                <strong>حالة الطلب </strong>
                                <p>({orderDetails.message})</p>
                            </div>
                            <p><strong>تفاصيل طلب رقم : </strong> {Ordernum}</p>
                            <p><strong>الاسم:</strong> {orderDetails.fullName}</p>
                            <br />
                            <h4>تفاصيل إضافية</h4>
                            <div className='prtItems-container'>
                                <div className='prtItems'>
                                    <p className='prtItem'><strong>الخدمة:</strong> {orderDetails.type}</p>
                                    <p className='prtItem prtItem11'><strong>تاريخ الإنشاء:</strong> {orderDetails.createdAt ? new Date(orderDetails.createdAt).toLocaleString() : 'غير متوفر'}</p>
                                    <p className='prtItem prtItem3'><strong>البريد الإلكتروني:</strong> {orderDetails.email}</p>
                                    <p className='prtItem prtItem5'><strong>تاريخ الزيارة:</strong> {orderDetails.periodDate}</p>
                                </div>
                                <div className='prtItems'>

                                    <p className='prtItem prtItem2'><strong>رقم الهاتف:</strong> <span dir="ltr">{orderDetails.phoneNumber}</span></p>
                                    <p className='prtItem prtItem1'><strong>الحي:</strong> {orderDetails.area}</p>
                                    <p className='prtItem prtItem4'><strong>العنوان:</strong> {orderDetails.addressLineOne}</p>
                                    <p className='prtItem prtItem6'><strong>وقت الزيارة:</strong> {orderDetails.periodFullTime}</p>
                                </div>
                            </div>
                            <br />
                            <p><strong>ملاحظ العميل:</strong> {orderDetails.userNote}</p>
                            <br />
                            {orderDetails.adminMessage && (
                                <p><strong>رسالة الإدارة:</strong> {orderDetails.adminMessage}</p>
                            )}

                            <div className='inform'>
                                <p>
                                    في حالة واجهتك اي مشكلة <Link to="/contact">تواصل معنا</Link>
                                </p>
                            </div>
                        </div>

                    );
                })()}



            </div >
            <Contact />
            <FooterPage />
        </div >
    );
};

export default FollowUp;
