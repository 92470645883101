import React, { useEffect, useState } from 'react';
import './OrderData.css';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import 'react-phone-input-2/lib/style.css';
import Loading from '../../Loading/Loading';

const OrderData = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { title } = useParams();

    useEffect(() => {
        const serviceId = sessionStorage.getItem('serviceId');

        if (!serviceId) {
            window.alert('برجاء تأكيد الحساب');
            setErrorMessage('برجاء تأكيد الحساب');
            navigate(`/order/${encodeURIComponent(title)}/verify`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const ISD = "+966";
    const [formData, setFormData] = useState({
        type: title,
        phoneNumber: '',
        ISD: ISD,
        addressLineOne: '',
        region: '',
        area: '',
        periodDate: '',
        periodFullTime: '',
        street: '',
        buildingNum: '',
        flatNum: '',
        fullName: '',
        userNote: '',
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleChange = (name, value) => {
        // Update formData only for the specific input field
        setFormData((prevData) => ({
            ...prevData,
            [name]: value, // Directly set the field value
        }));
    };

    const handleRegionChange = (selectedOption) => {
        const selectedRegion = selectedOption?.value || '';
        handleChange('region', selectedRegion);
        handleChange('area', '');
    };

    const handleAreaChange = (selectedOption) => {
        const selectedArea = selectedOption?.value || '';
        handleChange('area', selectedArea);
    };

    const handlePeriodChange = (selectedOption) => {
        const selectedPeriod = selectedOption?.value || '';
        handleChange('periodFullTime', selectedPeriod);
    };

    const handleSubmit = async () => {
        const serviceId = sessionStorage.getItem('serviceId');

        // Check if serviceId exists
        if (!serviceId) {
            setError('برجاء تأكيد الحساب والمحاولة مجدداً');
            navigate(`/order/${encodeURIComponent(title)}/verify`);
            return;
        }

        // Define validation rules and messages
        const validationRules = {
            fullName: {
                check: () => !!formData.fullName,
                message: 'برجاء إدخال الإسم كامل',
            },
            phoneNumber: {
                check: () => {
                    const number = formData.phoneNumber;
                    return number && number.length >= 9 && number.length <= 10; // Existing check
                },
                message: () => {
                    if (!formData.phoneNumber) return 'برجاء إدخال رقم الهاتف';
                    return 'الرقم غير صحيح';
                }
            },
            region: {
                check: () => !!formData.region,
                message: 'برجاء اختيار المنطقة',
            },
            area: {
                check: () => !!formData.area,
                message: 'برجاء اختيار الحي',
            },
            street: {
                check: () => !!formData.street,
                message: 'برجاء إدخال اسم الشارع',
            },
            buildingNum: {
                check: () => !!formData.buildingNum,
                message: 'برجاء إدخال رقم المبني',
            },
            flatNum: {
                check: () => !!formData.flatNum,
                message: 'برجاء إدخال رقم الشقة',
            },
            addressLineOne: {
                check: () => !!formData.addressLineOne,
                message: 'برجاء إدخال علامة مميزة',
            },
            periodDate: {
                check: () => {
                    const visitDate = new Date(formData.periodDate);
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);  // Reset time to midnight

                    // Create a date object for tomorrow
                    const tomorrow = new Date(today);
                    tomorrow.setDate(today.getDate() + 1); // Set to tomorrow's date
                    tomorrow.setHours(0, 0, 0, 0); // Reset time to midnight for comparison

                    return (
                        formData.periodDate &&
                        !isNaN(visitDate.getTime()) &&
                        visitDate.getTime() >= tomorrow.getTime() // Check if visitDate is tomorrow or later
                    );
                },
                message: () => {
                    const visitDate = new Date(formData.periodDate);
                    if (!formData.periodDate) return 'برجاء تحديد تاريخ الزيارة'; // Please select a visit date
                    if (isNaN(visitDate.getTime())) return 'تاريخ الزيارة غير صالح'; // Visit date is invalid
                    return 'لا يمكن تحديد تاريخ اليوم أو تاريخ سابق للزيارة'; // Cannot select today or past dates for the visit
                },
            },


            periodFullTime: {
                check: () => !!formData.periodFullTime,
                message: 'برجاء إختيار وقت الزيارة',
            },
            userNote: {
                check: () => {
                    const note = formData.userNote;
                    return note && note.length >= 20;
                },
                message: () => {
                    if (!formData.userNote) return 'برجاء إدخال تفاصيل المشكلة';
                    return 'اكتب أكثر عن تفاصيل المشكلة (20 حرف علي الأقل)';
                },
            },
        };

        // Validate each field
        for (const field in validationRules) {
            const rule = validationRules[field];
            if (!rule.check()) {
                setError(typeof rule.message === 'function' ? rule.message() : rule.message);
                return;
            }
        }


        const submissionData = {
            ...formData,
            phoneNumber: formData.phoneNumber,
        };

        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/v1/services/service/${serviceId}/follow-up`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(submissionData),
                }
            );

            if (!response.ok) {
                const errorResponse = await response.json();

                let errorMessage = 'حدث خطأ أثناء تنفيذ الطلب';
                if (errorResponse.message === "Invalid phone number format") {
                    errorMessage = 'الرقم غير صحيح';
                } else if (errorResponse.errors && errorResponse.errors.length > 0) {
                    const errorDetail = errorResponse.errors[0];
                    if (errorDetail.msg === "Date must be in the future") {
                        errorMessage = 'برجاء إختيار تاريخ زياره صالح (لا يمكن تحديد تاريخ اليوم للزيارة)';
                    }
                }

                throw new Error(errorMessage);
            }

            // If the request is successful, extract the response data
            const responseData = await response.json();

            // Save the serviceCode from the response in sessionStorage
            const serviceCode = responseData.data?.serviceCode;
            if (serviceCode) {
                sessionStorage.setItem('serviceCode', serviceCode);
                console.log('Service code saved:', serviceCode);
            } else {
                console.error('Service code not found in the response.');
            }

            // Navigate to the next page
            navigate(`/order/${encodeURIComponent(title)}/done`);
        } catch (error) {
            setError(error.message || 'حدث خطأ أثناء تنفيذ الطلب');
        } finally {
            setLoading(false);
        }
    };


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit();
        }
    };

    const areaData = {
        'الرياض': [
            'حي العليا', 'حي السليمانية', 'حي المغرزات', 'حي الشفاء', 'حي النخيل',
            'حي الملك فهد', 'حي القدس', 'حي المرسلات', 'حي المروج', 'حي الصحافة',
            'حي التعاون', 'حي الازدهار', 'حي البديعة', 'حي الربوة', 'حي الفلاح',
            'حي النزهة', 'حي الخزامى', 'حي النهضة', 'حي النسيم', 'حي الروضة',
            'حي الحمراء', 'حي اليرموك', 'حي غرناطة', 'حي الملقا', 'حي المونسية',
            'حي العارض', 'حي طويق', 'حي السويدي', 'حي ظهرة لبن', 'حي النرجس',
            'حي قرطبة', 'حي الرمال', 'حي العقيق', 'حي الياسمين', 'حي الدرعية',
            'حي الغدير', 'حي العريجاء', 'حي السلام', 'حي العزيزية', 'حي المهدية',
            'حي الندى', 'حي حطين', 'حي نمار', 'حي بدر', 'حي الخليج', 'حي النظيم',
            'حي الجزيرة', 'حي الفاخرية', 'حي أشبيلية', 'حي السعادة', 'حي المنار',
            'حي الدار البيضاء', 'حي الشميسي', 'حي المربع', 'حي الوزارات',
            'حي لبن', 'حي الفواز', 'حي الحزم', 'حي المصانع', 'حي بن تركي',
            'حي الدريهمية', 'حي شبرا', 'حي اليمامة', 'حي بن تركي', 'حي الحاير',
            'حي الشعلان', 'حي الرواد', 'حي الشهداء', 'حي السلي', 'حي الروابي',
            'حي سلطانة', 'حي العريجاء الأوسط', 'حي السويدي الغربي', 'حي ديراب',
            'حي ضاحية نمار', 'حي المؤتمرات', 'حي النموذجية', 'حي صياح',
            'حي أم سليم', 'حي الوشام', 'حي الناصرية', 'حي عليشة', 'حي الفيعة',
            'حي المعذر', 'حي الجرادية', 'حي الشرفية', 'حي الهدا', 'حي الزهرة',
            'حي الربيع', 'حي بنيان', 'حي الخير', 'جامعة الامام محمد بن سعود'
        ]
    };

    const regionOptions = Object.keys(areaData).map(region => ({
        value: region,
        label: region,
    }));

    const areaOptions = formData.region
        ? areaData[formData.region].map(area => ({
            value: area,
            label: area,
        }))
        : [];

    const periodOptions = [
        { value: "من ٨ ص إلى ١٢ م ", label: "من ٨ ص إلى ١٢ م " },
        { value: "من ١٢ م إلى ٤ م", label: "من ١٢ م إلى ٤ م" },
        { value: "من ٤ م إلى ٨ م", label: "من ٤ م إلى ٨ م" },
        { value: "من ٨ م إلى ١٢ ص", label: "من ٨ م إلى ١٢ ص" },
    ];


    return (
        <div id='Ord__data'>
            <h2>أدخل بيانات الطلب : {title}</h2>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <form className='form_ord__data' onKeyDown={handleKeyDown} dir='rtl'>
                <div className='ord-form-row'>
                    <label htmlFor="type">
                        الخدمة المختاره<br /> ( يتم تحديد سعر الخدمة بعد زيارة الفني )
                        <br />
                        <input
                            className='cust-input serv-name'
                            disabled
                            type="text"
                            name="type"
                            value={title}
                            readOnly
                        />

                    </label>

                    {error && <div style={{ width: "50%", color: "red" }} className="error-message">{error}</div>}
                </div>
                <div className='ord-form-row'>
                    <label htmlFor="fullname">
                        الاسم كامل
                        <input className='cust-input' type="text" name="fullName" value={formData.fullName} onChange={(e) => handleChange('fullName', e.target.value)} placeholder="اسم العميل" />
                    </label>
                    <label htmlFor="phoneNumber" dir='ltr' style={{ position: "relative" }}>
                        <span dir='rtl' className='phoneL'>الجوال</span>
                        <span dir='ltr' className='ISD'>+966 |</span>
                        <input
                            className='cust-input ISD-in'
                            type="phone"
                            value={formData.phoneNumber}
                            onChange={(e) => handleChange('phoneNumber', e.target.value)}
                            placeholder="رقم الهاتف"
                            minLength={9}
                            maxLength={10}
                        />

                    </label>
                </div>

                <div className='ord-form-row'>
                    <label htmlFor="region-select">
                        اختر المنطقة:
                        <Select
                            id="region-select"
                            options={regionOptions}
                            onChange={handleRegionChange}
                            placeholder="اختر المنطقة"
                            isClearable
                        />
                    </label>

                    {formData.region && (
                        <label htmlFor="area-select">
                            اختر الحي:
                            <Select
                                id="area-select"
                                options={areaOptions}
                                onChange={handleAreaChange}
                                placeholder="اختر الحي"
                                isClearable
                            />
                        </label>
                    )}
                </div>

                <div className='ord-form-row'>
                    <label htmlFor="street">
                        اسم الشارع
                        <input className='cust-input' type="text" name="street" value={formData.street} onChange={(e) => handleChange('street', e.target.value)} placeholder="الشارع" />
                    </label>
                    <label htmlFor="buildingNum">
                        رقم المبنى
                        <input className='cust-input' type="number" name="buildingNum" min="0" value={formData.buildingNum} onChange={(e) => handleChange('buildingNum', e.target.value)} placeholder="رقم المبني" />
                    </label>
                    <label htmlFor="flatNum">
                        رقم الشقة
                        <input className='cust-input' type="number" name="flatNum" min="0" value={formData.flatNum} onChange={(e) => handleChange('flatNum', e.target.value)} placeholder="رقم الشقة" />
                    </label>
                </div>

                <div className='ord-form-row'>
                    <label htmlFor="addressLineOne">
                        علامة مميزة
                        <input className='cust-input' type="text" name="addressLineOne" value={formData.addressLineOne} onChange={(e) => handleChange('addressLineOne', e.target.value)} placeholder="علامة مميزة" />
                    </label>
                    <label htmlFor="periodDate">
                        تاريخ الزيارة <small> (لا يمكن استقبال الطلبات في نفس اليوم)</small>
                        <input
                            className='cust-input'
                            type="date"
                            name="periodDate"
                            value={formData.periodDate}
                            onChange={(e) => handleChange('periodDate', e.target.value)}
                        />
                    </label>

                    <label htmlFor="periodFullTime">
                        وقت الزيارة
                        <Select
                            id="periodFullTime"
                            options={periodOptions}
                            onChange={handlePeriodChange}
                            placeholder="اختر وقت الزيارة"
                            isClearable
                        />
                    </label>
                </div>

                <label htmlFor="userNote">
                    تفاصيل المشكلة
                    <textarea
                        style={{ marginRight: "0" }}
                        className='cust-input'
                        name="userNote"
                        value={formData.userNote} // Default value
                        onChange={(e) => handleChange('userNote', e.target.value)}
                        placeholder="تفاصيل المشكلة"
                    />
                </label>


                <div className="ord-form-actions">
                    {loading ? <Loading /> : <button className='G-btn' type="button" onClick={handleSubmit}>إرسال</button>}
                </div>
                <br />
                {error && <div style={{ width: "50%", color: "red" }} className="error-message">{error}</div>}
                {success && <div className="success-message">{success}</div>}
            </form>
        </div>
    );
};

export default OrderData;
