import React, { useEffect, useState } from 'react';
import { CiCircleCheck } from "react-icons/ci";
import { BiCopy } from "react-icons/bi"; // Import the copy icon
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';

const OrderDone = () => {
    const [serviceCode, setServiceCode] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [errorMessage, setErrorMessage] = useState('');
    const [copySuccess, setCopySuccess] = useState(false); // To handle copy success message
    const navigate = useNavigate();
    const { title } = useParams();

    useEffect(() => {
        const savedServiceCode = sessionStorage.getItem('serviceCode');
        if (savedServiceCode) {
            setServiceCode(savedServiceCode);
        }
        if (!savedServiceCode) {
            window.alert('برجاء تأكيد الحساب');
            setErrorMessage('برجاء تأكيد الحساب');
            navigate(`/order/${encodeURIComponent(title)}/verify`);
        }

        return () => {
            sessionStorage.removeItem('serviceCode');
            sessionStorage.removeItem('serviceId');
            sessionStorage.removeItem('email');
        };
    }, [navigate, title]);

    const handleCopy = () => {
        if (serviceCode) {
            navigator.clipboard.writeText(serviceCode);
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000); // Hide the success message after 2 seconds
        }
    };

    return (
        <div className='order-done'>
            <i>
                <CiCircleCheck />
            </i>
            <h4 style={{ marginBottom: "0" }}>تم إستلام طلبك بنجاح, سيتم التواصل في أقرب وقت</h4>

            {serviceCode && (
                <p style={{ textAlign: "center", height: "100%" }}>
                    كود الخدمة الخاص بك هو: <br />
                    {copySuccess && <span style={{ color: "green", fontSize: "0.75rem", marginRight: "10px" }}>تم النسخ</span>}
                    <strong
                        style={{
                            backgroundColor: "#F4F4F4",
                            padding: "0rem 0.8rem",
                            border: "1px solid #bbbaba",
                            borderRadius: "5px",
                            display: "inline-flex",
                            alignItems: "center"
                        }}>
                        {serviceCode}
                        {/* Copy icon */}
                        <BiCopy
                            onClick={handleCopy}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: "10px",
                                cursor: "pointer",
                                color: "#4463AF"
                            }}
                            title="نسخ"
                        />
                    </strong>

                </p>
            )}

            <h5>(يمكنك التوجة الي صفحة <Link to="/FollowUp">المتابعة</Link>, لمتابعة طلبك)</h5>
        </div>
    );
};

export default OrderDone;
