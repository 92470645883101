import React, { useState } from 'react';
import './OrderVerify.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Loading from '../../Loading/Loading';

const OrderVerify = () => {
    const { title } = useParams();
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [resendDisabled, setResendDisabled] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleEmailSub = async () => {
        if (email) {
            setLoading(true);
            try {
                // Make the POST request to send OTP email
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/services/send-otp-email`, { email });

                // Destructure serviceId from response
                const { serviceId } = response.data;

                // Set email submitted and start countdown for resend button
                setEmailSubmitted(true);
                startResendCountdown();

                // Store serviceId and email in sessionStorage
                sessionStorage.setItem('serviceId', serviceId);
                sessionStorage.setItem('email', email);
            } catch (error) {
                // Log the actual error to check what went wrong
                console.error('Error sending OTP email:', error);

                // Provide feedback to the user in case of failure
                alert('خطأ في إرسال رمز التحقق, حاول مجدداً');
            } finally {
                // Stop the loading spinner
                setLoading(false);
            }
        } else {
            // Inform the user to enter a valid email
            alert('برجاء استخدام بريد إليكتروني صحيح');
        }
    };

    const handleVerification = async () => {
        if (otp) {
            setLoading(true);
            try {
                // Sending POST request to verify the email and OTP
                await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/services/verify-email`, {
                    email,
                    otpCode: otp,
                    serviceId: sessionStorage.getItem('serviceId'), // Retrieve serviceId from sessionStorage
                });

                // Storing serviceId and email in sessionStorage for further use


                // Navigate to the next page after successful verification
                navigate(`/order/${encodeURIComponent(title)}/data`);
            } catch (error) {
                // Log error to console for debugging purposes
                console.error('Verification Error:', error);
                alert('رمز التحقق غير صحيح');
            } finally {
                setLoading(false); // Stop loading spinner
            }
        } else {
            alert('من فضلك أدخل رمز التحقق');
        }
    };


    const handleReSendOtp = async () => {
        if (!resendDisabled && !loading) {
            setLoading(true);
            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/services/send-otp-email`, { email });
                startResendCountdown();
            } catch (error) {
                alert('تعذر إرسال رمز تحقق جديد,حاول مجدداً');
            } finally {
                setLoading(false);
            }
        }
    };

    const startResendCountdown = () => {
        setResendDisabled(true);
        setCountdown(60);
        const timer = setInterval(() => {
            setCountdown(prev => {
                if (prev <= 1) {
                    clearInterval(timer);
                    setResendDisabled(false);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    const formatCountdown = () => {
        const minutes = Math.floor(countdown / 60);
        const seconds = countdown % 60;
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    const handleKeyDown = (e, callback) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            callback();
        }
    };

    return (
        <div id='verify'>
            <div className='form-head'>
                <h3>التحقق من البريد الإلكتروني</h3>
            </div>
            <div className='form-content'>
                {!emailSubmitted ? (
                    <>
                        <input
                            dir='rtl'
                            type="email"
                            name="Vemail"
                            id="Vemail"
                            placeholder='البريد الإكتروني'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, handleEmailSub)} // Add keyDown event
                        />
                        {loading ? <Loading /> :
                            <button className='G-btn' onClick={handleEmailSub} disabled={loading}>
                                تأكيد البريد
                            </button>
                        }
                    </>
                ) : (
                    <div>
                        <input
                            dir='rtl'
                            type="text"
                            name="otp"
                            id="otp"
                            placeholder='أدخل رمز التحقق'
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, handleVerification)} // Add keyDown event
                        />
                        <div className='btn-container'>
                            {loading ? <Loading /> :
                                <button className='G-btn' onClick={handleVerification} disabled={loading}>
                                    تأكيد
                                </button>
                            }
                            <button
                                className='G-btn'
                                onClick={handleReSendOtp}
                                disabled={resendDisabled || loading}
                            >
                                {resendDisabled ? `إعادة إرسال بعد ${formatCountdown()}` : 'رمز تحقق جديد'}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrderVerify;



// import React, { useState } from 'react';
// import PhoneVerify from './PhoneVerify';
// import EmailVerify from './EmailVerify';

// const OrderVerify = () => {
//     const [emailSubmitted, setEmailSubmitted] = useState(false);

//     return (
//         <div>
//             {!emailSubmitted ? (
//                 < div style={{ position: "relative", paddingBottom: "1.5rem" }}>
//                     <PhoneVerify />
//                     <button className='G-btn T-btn' onClick={() => setEmailSubmitted(true)}>تأكيد باستخدام البريد</button>
//                 </div>
//             ) : (
//                 < div style={{ position: "relative", paddingBottom: "1.5rem" }}>
//                     <EmailVerify />
//                     <button className='G-btn T-btn' onClick={() => setEmailSubmitted(false)}>تأكيد باستخدام الجوال</button>
//                 </div>

//             )}
//         </div>
//     );
// };

// export default OrderVerify;
