
import React from 'react'
import './Footer.css';
import { BsFillPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { TbClockHour4Filled } from "react-icons/tb";
import { Link as LinkS } from 'react-scroll';
import { Link } from 'react-router-dom';

function FooterPage() {
    // const currentYear = new Date().getFullYear();

    return (

        <footer id='footer'>
            <div className="footer">
                <div className='mobile'>
                    <div style={{ width: '50%' }} id='footertow' className="column">
                        <h3>روابط تهمك</h3>
                        <ul>
                            <li><Link to="/">الرئيسية </Link></li>
                            <div className='under-border'></div>
                            <li><LinkS to="contact">للتواصل</LinkS></li>
                            <div className='under-border'></div>
                            <li><Link to='/aboutus'>من نحن</Link></li>
                            <div className='under-border'></div>
                        </ul>
                    </div>
                    <div style={{ width: '50%' }} id='footerone' className="column">
                        <h3>تواصل معنا</h3>
                        <div className="bord phone">
                            <i className='phone'><BsFillPhoneFill /></i>
                            <div className='bord-cont'>
                                <p className='H'>الجوال</p>
                                <p dir='ltr'>+966560907329</p>
                            </div>

                        </div>
                        <div className="bord mail">
                            <i className='mail'><MdEmail /></i>
                            <div className='bord-cont'>
                                <p>البريد الإلكتروني</p>
                                <p> <a href="mailto:ahmed.sayed@trendlineeg.com" target="_blank" rel="noopener noreferrer">info@norhomecare.com</a> </p>
                            </div>
                        </div>
                        <div className="bord address">
                            <i className='address'><FaMapMarkerAlt /></i>
                            <div className='bord-cont'>
                                <p>العنوان</p>
                                <p>المملكة العربية السعودية - الرياض - مدينة الملك عبدالعزيز- الكراج</p>
                            </div>

                        </div>
                        <div className="bord time">
                            <i className='time'><TbClockHour4Filled /></i>
                            <div className='bord-cont'>
                                <p>مواعيد العمل</p>
                                <p>من ٨ ص ل ١٢ ص ماعدا الجمعة من ٤ م ل١٢ ص</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='column' style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <LinkS to="About" className=' cAbout'>
                        <h3>من نحن</h3>
                        <div className="bord aboute">
                            <p>مرحبًا بكم في نور - NOR، الوجهة الأولى لخدمات الصيانة المنزلية المتكاملة. نحن فريق من الخبراء المتخصصين في تقديم حلول شاملة ومبتكرة لصيانة المنازل، بهدف ضمان راحتك وسلامة منزلك. تأسس نور - NOR بناءً على رؤية واضحة لتوفير خدمات صيانة موثوقة وسريعة، تلبي احتياجات كل منزل وتضمن استمرارية تشغيل جميع الأنظمة بكفاءة.</p>
                        </div>
                    </LinkS>
                </div>
            </div>
            <br />
            <div className='footer-end' dir='rtl'>
                <h3> <div className='bg-cover'> تصميم وتطوير </div> <span> <a className='showLink' target="_blank" rel="noreferrer" href="https://missanvip.com/">https://missanvip.com</a> <a target="_blank" rel="noreferrer" href="https://missanvip.com/">ميسان</a>  </span></h3>
                <h3 dir='ltr' >Norhomecare. All rights reserved</h3>
            </div>
        </footer >
    )
}

export default FooterPage