import React from 'react'
import './Loading.css'
import { RiseLoader } from 'react-spinners'

const Loading = () => {
    return (
        <div className='loading-sec'>
            <RiseLoader color="#4463AF" />
        </div>
    )
}

export default Loading