import React, { useEffect, useRef, useState } from 'react';
import './Nav.css';
import Navbar from 'react-bootstrap/Navbar';
import { BiMenuAltLeft, BiX } from "react-icons/bi";
import Nlogo from '../../../assets/nav-logo.png';
import { Link } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import classNames from 'classnames';
import { FaWhatsapp } from "react-icons/fa";
import { GrMapLocation } from "react-icons/gr";
import { FaXTwitter } from 'react-icons/fa6';
import { IoCallOutline } from 'react-icons/io5';

function PageNav() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isNavBg, setIsNavBg] = useState(false);
    const [activeNav, setActiveNav] = useState('header');
    const navContainerRef = useRef(null);

    const toggleMenu = () => setIsMenuOpen(prev => !prev);
    const closeMenu = () => setIsMenuOpen(false);

    const changeBg = () => {
        setIsNavBg(window.scrollY >= 0);
    };

    const handleClickOutside = (event) => {
        if (navContainerRef.current && !navContainerRef.current.contains(event.target)) {
            closeMenu();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeBg);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('scroll', changeBg);
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const sections = ['contact', 'Aboutpage', 'FollowUp'];
            let currentSection = 'header';

            for (let i = 0; i < sections.length; i++) {
                const section = document.getElementById(sections[i]);
                if (section) {
                    const sectionTop = section.getBoundingClientRect().top + window.scrollY;
                    const sectionBottom = sectionTop + section.offsetHeight;

                    if (window.pageYOffset >= sectionTop - 500 && window.pageYOffset < sectionBottom - 100) {
                        currentSection = `#${sections[i]}`;
                        break;
                    }
                }
            }

            setActiveNav(currentSection);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <section id='navbar'>
            <Navbar fixed="top" expand="lg" className={classNames('navbar navbar-bg', { 'navbar-bg': isNavBg })}>
                <div className='menu'>
                    <i><BiMenuAltLeft onClick={toggleMenu} /></i>
                </div>
                <div className="nav-container" ref={navContainerRef}>
                    <div dir='rtl'
                        className={classNames('topnav', { 'Svisible': isMenuOpen, 'closed': !isMenuOpen })}
                        id='nav-container'
                    >
                        <div className="close-btn" onClick={(e) => { e.stopPropagation(); closeMenu(); }}>
                            <BiX />
                        </div>
                        <Link to='/'>الرئيسية</Link>
                        <Link to='/aboutus' className={activeNav === '#Aboutpage' ? 'Nactive touch' : 'touch'}>من نحن</Link>
                        <Link to='/FollowUp' className={activeNav === '#FollowUp' ? 'Nactive touch' : 'touch'}>متابعة</Link>
                        <LinkS to='contact' className={activeNav === '#contact' ? 'Nactive touch' : 'touch'}>للتواصل</LinkS>

                        <div className='nave-links' dir='ltr'>
                            <a target="_blank"
                                rel="noreferrer" href="https://maps.app.goo.gl/o1qw696v3TpnSniA9?g_st=iw"><i className='nav-Linkbt'><GrMapLocation /></i></a>

                            <a target="_blank"
                                rel="noreferrer" href="https://api.whatsapp.com/send?phone=9660560907329"><i className='nav-Linkbt'><FaWhatsapp /></i></a>
                            <a target="_blank"
                                rel="noreferrer" href="tel:+966560907329"><i className='nav-Linkbt'><IoCallOutline /></i></a>

                            <a target="_blank"
                                rel="noreferrer" href="https://x.com/NOR_Support"><i className='nav-Linkbt'><FaXTwitter /></i></a>
                        </div>
                    </div>
                    <Navbar.Brand href="/" className='logo'>
                        <img
                            data-aos="fade-right"
                            data-aos-easing="ease"
                            data-aos-duration="1000"
                            data-aos-offset="0" src={Nlogo} alt="Nav Logo" />
                    </Navbar.Brand>
                </div>
            </Navbar>
        </section>
    );
}

export default PageNav;
