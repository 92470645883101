import React from 'react';
import contactImg from '../../../assets/contactus.png'
import { IoIosPhonePortrait } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt, FaWhatsapp } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
import { GoClock } from "react-icons/go";
import { GrMapLocation } from "react-icons/gr";
import { FaXTwitter } from "react-icons/fa6";
import './Contact.css'

function Contact() {

    return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "3rem" }}>
            <div id="contact">
                <div className="contact-container" dir='rtl'>
                    <h2><span>تواجدنا هنا</span></h2>
                    <div className="conatct-top">
                        <div className="cont-left">
                            <div className="bord phone"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="300">
                                <i className='phone'><IoIosPhonePortrait /></i>
                                <div className='bord-cont'>
                                    <p className='H'><strong> الهاتف </strong></p>
                                    <p dir='ltr'>+966560907329</p>
                                </div>

                            </div>
                            <div className="bord mail"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="500">
                                <i className='mail'><MdEmail /></i>
                                <div className='bord-cont'>
                                    <p><strong>البريد الإلكتروني</strong></p>
                                    <p>info@norhomecare.com</p>
                                </div>

                            </div>
                            <div className="bord address"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="700">
                                <i className='address'><FaMapMarkerAlt /></i>
                                <div className='bord-cont'>
                                    <p><strong>العنوان </strong></p>
                                    <p>الرياض - مدينة الملك عبدالعزيز - الكراج</p>
                                </div>

                            </div>
                            <div className="bord time"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="900">
                                <i className='time'><GoClock /></i>
                                <div className='bord-cont'>
                                    <p><strong>مواعيد العمل </strong></p>
                                    <p>من ٨ ص ل ١٢ ص ماعدا الجمعة من ٤ م ل١٢ ص</p>
                                </div>
                            </div>
                            <div className="bordI"
                                data-aos="fade-down-left"
                                data-aos-offset="150"
                                data-aos-duration="900" dir='ltr'>
                                <a target="_blank"
                                    rel="noreferrer" href="https://maps.app.goo.gl/o1qw696v3TpnSniA9?g_st=iw"><i className='Linkbt'><GrMapLocation /></i></a>

                                <a target="_blank"
                                    rel="noreferrer" href="https://api.whatsapp.com/send?phone=9660560907329"><i className='Linkbt'><FaWhatsapp /></i></a>
                                <a target="_blank"
                                    rel="noreferrer" href="tel:+966560907329"><i className='Linkbt'><IoCallOutline /></i></a>

                                <a target="_blank"
                                    rel="noreferrer" href="https://x.com/NOR_Support"><i className='Linkbt'><FaXTwitter /></i></a>

                            </div>
                        </div>

                        <div className="cont-right">
                            <div className="map" data-aos="fade-down"
                                data-aos-offset="100"
                                data-aos-duration="1000">
                                <img src={contactImg} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact