import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import LoginPage from './Pages/login/login';
import Admin from './Pages/Admin/adminpage';
import Order from './Pages/Order/Order';
import OrderVerify from './components/OrderComp/OrderVerify/OrderVerify';
import OrderData from './components/OrderComp/OrderData/OrderData';
import OrderDone from './components/OrderComp/OrderDone/OrderDone';
import AboutPage from './Pages/AboutPage/AboutPage';
import ContactPage from './Pages/ContactPage/ContactPage';
import FollowUp from './Pages/FollowUp/FollowUp';

function App() {
  return (
    <div className="main">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/FollowUp" element={<FollowUp />} />
          <Route path="/login" element={<LoginPage />} />

          <Route path="/admin/*" element={<Admin />} />

          <Route path="/order/:title/*" element={<Order />}>
            <Route path="verify" element={<OrderVerify />} />
            <Route path="data" element={<OrderData />} />
            <Route path="done" element={<OrderDone />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
