import React from 'react';
import './TrackerFV.css';

const PageTracingVF = ({ stages }) => {
    return (
        <div className="tracing-containerVF" dir="ltr">
            <div className="tracing-row">
                {stages.map((stage, index) => (
                    <div
                        key={index}
                        className={`order-trackingVF ${stage.completed ? 'completedVF' : ''} ${stage.current ? 'currentVF' : ''}`}
                    >
                        <span className="is-complete">
                            {stage.completed && <span className="checkmarkVF"></span>}
                        </span>
                        <p>{stage.label}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PageTracingVF;
