import React from 'react'
import './AdminSideBar.css'
import { Link } from 'react-router-dom'

const AdminSideBar = () => {
    return (
        <div className='adminSB'>
            <div className='adminSB-links'>
                <Link className='G-btn2' to='pinding'>معلق</Link>
                <Link className='G-btn2' to='in-progress'>تنفيذ</Link>
                <Link className='G-btn2' to='done'> منتهي</Link>
                <Link className='G-btn2' to='canceled'> ملغي</Link>
            </div>
        </div>
    )
}

export default AdminSideBar