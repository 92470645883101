import React from 'react'
import ShowCase from '../../components/HomeComp/ShowCase/ShowCase'
import About from '../../components/HomeComp/About/About'
import ServicesSec from '../../components/HomeComp/ServicesSec/ServicesSec'
import Nav from '../../components/HomeComp/Nav/Nav'
import Contact from '../../components/HomeComp/Contact/Contact'
import Footer from '../../components/HomeComp/Footer/Footer'
import { Helmet } from 'react-helmet'
import UpOrder from '../../components/HomeComp/UpOrder/UpOrder'

const Home = () => {
    return (
        <div>
            <Helmet>
                <meta name="description" content="الوجهة الأولى لخدمات الصيانة المنزلية المتكاملة. نحن فريق من الخبراء المتخصصين في تقديم حلول شاملة ومبتكرة لصيانة المنازل، بهدف ضمان راحتك وسلامة منزلك" />
                <link rel="apple-touch-icon" href="https://norhomecare.com/logo192.png" />
                <link rel="manifest" href="https://norhomecare.com/manifest.json" />

                <title>نور لخدمات الصيانة المنزليه</title>
                <meta name="author" content="Dev:Mohamed Metwally Design:Basiony Mamdoh" />

                <meta property="og:title" content="نور لخدمات الصيانة المنزليه" />
                <meta property="og:description"
                    content="الوجهة الأولى لخدمات الصيانة المنزلية المتكاملة. نحن فريق من الخبراء المتخصصين في تقديم حلول شاملة ومبتكرة لصيانة المنازل، بهدف ضمان راحتك وسلامة منزلك" />
                <meta property="og:image" content="https://norhomecare.com/Home.jpg" />
                <meta property="og:url" content="https://norhomecare.com/" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://norhomecare.com/" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="نور لخدمات الصيانة المنزليه" />
                <meta name="twitter:description"
                    content="الوجهة الأولى لخدمات الصيانة المنزلية المتكاملة. نحن فريق من الخبراء المتخصصين في تقديم حلول شاملة ومبتكرة لصيانة المنازل، بهدف ضمان راحتك وسلامة منزلك" />
                <meta name="twitter:image" content="https://norhomecare.com/Home.jpg" />

                <meta name="keywords"
                    content="الوجهة, الأولى, لخدمات, الصيانة, المنزلية, المتكاملة, نحن, فريق, من, الخبراء, المتخصصين, في, تقديم, حلول, شاملة, ومبتكرة, لصيانة, المنازل, بهدف, ضمان, راحتك, وسلامة, منزلك" />
            </Helmet>
            <Nav />
            <ShowCase />
            <About />
            <ServicesSec />
            <UpOrder />
            <Contact />
            <Footer />
        </div>
    )
}

export default Home