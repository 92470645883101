import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import './Order.css';
import NavPage from '../../components/HomeComp/Nav/PageNav';
import Contact from '../../components/HomeComp/Contact/Contact';
import FooterPage from '../../components/HomeComp/Footer/FooterPage';
import PageTracing from '../../components/OrderComp/Tracker/Tracker';
import { Helmet } from 'react-helmet';

const Order = () => {

    const currentUrl = window.location.href;
    const location = useLocation();

    // Define stages without the date property
    const stages = [
        {
            label: 'تأكيد البريد',
            current: location.pathname.includes('verify'),
            completed: location.pathname.includes('data') || location.pathname.includes('done')
        },
        {
            label: 'طلب الخدمة',
            current: location.pathname.includes('data'),
            completed: location.pathname.includes('done')
        },
        {
            label: 'تم',
            current: location.pathname.includes('done'),
            completed: location.pathname.includes('done')
        }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);

    return (
        <div>
            <Helmet>
                <meta name="description" content="الوجهة الأولى لخدمات الصيانة المنزلية المتكاملة. نحن فريق من الخبراء المتخصصين في تقديم حلول شاملة ومبتكرة لصيانة المنازل، بهدف ضمان راحتك وسلامة منزلك" />
                <link rel="apple-touch-icon" href="https://norhomecare.com/logo192.png" />
                <link rel="manifest" href="https://norhomecare.com/manifest.json" />

                <title>نور لخدمات الصيانة المنزليه - الحجز</title>
                <meta name="author" content="Dev:Mohamed Metwally Design:Basiony Mamdoh" />

                <meta property="og:title" content="نور لخدمات الصيانة المنزليه - الحجز" />
                <meta property="og:description"
                    content="الوجهة الأولى لخدمات الصيانة المنزلية المتكاملة. نحن فريق من الخبراء المتخصصين في تقديم حلول شاملة ومبتكرة لصيانة المنازل، بهدف ضمان راحتك وسلامة منزلك" />
                <meta property="og:image" content="https://norhomecare.com/Home.jpg" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:type" content="website" />
                <link rel="canonical" href={currentUrl || "https://norhomecare.com/order/:title/*"} />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="نور لخدمات الصيانة المنزليه - الحجز" />
                <meta name="twitter:description"
                    content="الوجهة الأولى لخدمات الصيانة المنزلية المتكاملة. نحن فريق من الخبراء المتخصصين في تقديم حلول شاملة ومبتكرة لصيانة المنازل، بهدف ضمان راحتك وسلامة منزلك" />
                <meta name="twitter:image" content="https://norhomecare.com/Home.jpg" />

                <meta name="keywords"
                    content="الوجهة, الأولى, لخدمات, الصيانة, المنزلية, المتكاملة, نحن, فريق, من, الخبراء, المتخصصين, في, تقديم, حلول, شاملة, ومبتكرة, لصيانة, المنازل, بهدف, ضمان, راحتك, وسلامة, منزلك" />
            </Helmet>
            <NavPage />
            <div id='Order'>
                <PageTracing stages={stages} currentStageIndex={stages.findIndex(stage => stage.completed)} /> {/* Render page tracing */}
                <div className='Order'>
                    <Outlet /> {/* Render nested routes */}
                </div>
            </div>
            <Contact />
            <FooterPage />
        </div>
    );
};

export default Order;
