import React, { useState, useEffect } from 'react';
import './login.css'
import { loadStyles } from '../../utils/loadStyles';
import logimg from '../../assets/nav-logoB.png';
import { Link, useNavigate } from 'react-router-dom';
import {
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBRow,
    MDBCol,
    MDBInput
} from 'mdb-react-ui-kit';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const removeStyles = loadStyles('https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/5.0.0/mdb.min.css');

        return () => {
            removeStyles();
        };
    }, []);

    // Check authentication status on component mount
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/admin');
        }
    }, [navigate]);

    // Handle login form submission
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/auth/signin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to login: ${errorText}`);
            }

            const data = await response.json();
            if (data.success) {
                localStorage.setItem('token', data.data);
                navigate('/admin');
            } else {
                setError('Login failed: ' + data.message);
            }
        } catch (err) {
            setError(err.message);
            console.error('Error details:', err);
        }
    };

    return (
        <div id='Login'>
            <MDBContainer className="my-5">
                <MDBCard className='log-bg'>
                    <MDBRow className='g-0' style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                        <MDBCol md='5' style={{ display: "flex", justifyContent: "centere", alignItems: "center" }}>
                            <Link to="/">
                                <MDBCardImage src={logimg} alt="login form" className='rounded-start w-65' />
                            </Link>
                        </MDBCol>
                        <MDBCol md='6' className='LogForm'>
                            <MDBCardBody className='d-flex flex-column justify-content-center align-items-center w-100'>
                                <h5 className="fw-normal my-4 pb-3" style={{ letterSpacing: '1px' }}>تسجيل دخول</h5>

                                <form onSubmit={handleLogin}>
                                    البريد الالكتروني
                                    <MDBInput
                                        wrapperClass='mb-4'
                                        placeholder='البريد الالكتروني'
                                        id='formControlLg'
                                        type='email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        size="lg"
                                    />
                                    كلمة المرور
                                    <MDBInput
                                        wrapperClass='mb-4'
                                        placeholder='كلمة المرور'
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        id='formControlLg1'
                                        type='password'
                                        size="lg"
                                    />

                                    <MDBBtn className="mb-4 px-5" color='dark' type="submit" size='lg'>تسجيل دخول</MDBBtn>
                                </form>

                                {error && <p className="text-danger">{error}</p>}

                            </MDBCardBody>
                        </MDBCol>
                    </MDBRow>
                </MDBCard>
            </MDBContainer>
        </div>
    );
};

export default LoginPage;
