import React from 'react'
import './ContactPage.css'
import NavPage from '../../components/HomeComp/Nav/PageNav'
import Contact from '../../components/HomeComp/Contact/Contact'
import FooterPage from '../../components/HomeComp/Footer/FooterPage'

const ContactPage = () => {
    return (
        <>
            <NavPage />
            <Contact />
            <FooterPage />
        </>
    )
}

export default ContactPage