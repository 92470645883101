import React from 'react';
import './Tracing.css';

const PageTracing = ({ stages }) => {
    return (
        <div className="tracing-container">
            <div className="tracing-row">
                {stages.map((stage, index) => (
                    <div
                        key={index}
                        className={`order-tracking ${stage.completed ? 'completed' : ''} ${stage.current ? 'current' : ''}`}
                    >
                        <span className="is-complete">
                            {stage.completed && <span className="checkmark"></span>}
                        </span>
                        <p>{stage.label}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PageTracing;
