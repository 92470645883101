import React, { useRef, useState } from 'react';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown'; // Ensure you have this library installed
import { Button } from 'primereact/button'; // Ensure you have this library installed
import { Toast } from 'primereact/toast';  // For notifications
import { Dialog } from 'primereact/dialog'; // Ensure Dialog is imported
import './SendMsg.css';

const SendMsg = (props) => {
    const [serviceStatus, setServiceStatus] = useState('');
    const [selectedStatusLabel, setSelectedStatusLabel] = useState('');
    const [visibleEmailDialog, setVisibleEmailDialog] = useState(false); // For email dialog visibility
    const [adminEmail, setAdminEmail] = useState(''); // For the email body/note
    const toast = useRef(null);  // Ref for toast notifications

    const statusOptions = [
        { label: 'في الطريق', value: 'in-progress-notify/way' },    // Notify Way
        { label: 'إنتظار التأكيد', value: 'in-progress-notify/insure' }, // Notify Insure
        { label: 'جاري الصيانة', value: 'in-progress-notify/in-location' }, // Notify In Location
    ];

    const markDone = async () => {
        if (!props.ServiceId) {
            // Show a warning if no ServiceId is provided
            toast.current.show({ severity: 'warn', summary: 'تحذير', detail: 'يرجى تحديد خدمة.', life: 3000 });
            return;
        }

        if (!serviceStatus) {
            alert('Please select a status'); // Alert if no status is selected
            return;
        }

        const token = localStorage.getItem('token'); // Fetch the token from localStorage

        try {
            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/v1/admin/service/${props.ServiceId}/status/${serviceStatus}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`, // Include the token in the request headers
                },
            });

            console.log('Success:', response.data);
            // Show success toast message
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'تم تنفيذ العملية بنجاح.', life: 3000 });
        } catch (error) {
            console.error('Error:', error);
            // Show error toast message
            toast.current.show({ severity: 'error', summary: 'خطأ', detail: 'حدث خطأ أثناء تنفيذ العملية.', life: 3000 });
        }
    };

    const sendEmail = async () => {
        if (!adminEmail.trim()) {
            toast.current.show({ severity: 'warn', summary: 'تحذير', detail: 'يرجى إدخال ملاحظة.', life: 3000 });
            return;
        }

        const token = localStorage.getItem('token'); // Fetch the token from localStorage

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/admin/service/${props.ServiceId}/send-email`, {
                body: adminEmail
            }, {
                headers: {
                    Authorization: `Bearer ${token}`, // Include the token in the request headers
                },
            });

            console.log('Email sent:', response.data);
            toast.current.show({ severity: 'success', summary: 'تم', detail: 'تم إرسال البريد الإلكتروني بنجاح.', life: 3000 });
            setVisibleEmailDialog(false); // Close the dialog after sending
        } catch (error) {
            console.error('Error sending email:', error);
            toast.current.show({ severity: 'error', summary: 'خطأ', detail: 'حدث خطأ أثناء إرسال البريد الإلكتروني.', life: 3000 });
        }
    };

    return (
        <>
            <div className='table-headerr'>
                <Dropdown
                    value={serviceStatus}
                    options={statusOptions}
                    onChange={(e) => {
                        const selectedOption = statusOptions.find(option => option.value === e.value); // Find the selected option
                        setServiceStatus(e.value);
                        setSelectedStatusLabel(selectedOption ? selectedOption.label : ''); // Set the label of the selected option
                    }}
                    placeholder="Select Status"
                />
                <Button
                    style={{ backgroundColor: "green", border: "none" }}
                    label={`إشعار: ${selectedStatusLabel || 'حدد إشعار'}`} // Display selected status or placeholder
                    icon="pi pi-bell"
                    onClick={markDone}
                    disabled={!serviceStatus} // Disable button if no status is selected
                />
                {/* Toast for notifications */}
                <Toast ref={toast} />
                <div className='table-headerr'>
                    <Button
                        style={{ backgroundColor: "green", border: "none" }}
                        label={`إرسال بريد إلكتروني`}
                        icon="pi pi-envelope"
                        onClick={() => setVisibleEmailDialog(true)} // Open the dialog on click
                        disabled={!props.ServiceId}
                    />
                    {/* Toast for notifications */}
                    <Toast ref={toast} />
                </div>
            </div>

            {/* Email Dialog */}

            <Dialog header="إضافية ملاحظة" visible={visibleEmailDialog} onHide={() => setVisibleEmailDialog(false)}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <textarea
                        value={adminEmail}
                        onChange={(e) => setAdminEmail(e.target.value)}
                        rows={5}
                        cols={30}
                        placeholder="أدخل الملاحظة هنا..."
                        style={{ width: '100%', height: '100px' }}
                    />
                    <div style={{ marginTop: '10px', textAlign: 'right' }}>
                        <Button label="إرسال" icon="pi pi-check" onClick={sendEmail} />
                        <Button label="إلغاء" icon="pi pi-times" onClick={() => setVisibleEmailDialog(false)} style={{ marginLeft: '10px' }} />
                    </div>
                </div>
            </Dialog>

        </>
    );
};

export default SendMsg;
