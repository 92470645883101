import React, { useEffect, useState } from 'react';
import './Admin.css';
import { Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import DonePage from '../../components/AdminComp/DonePage/DonePage';
import PinddingPage from '../../components/AdminComp/PinddingPage/PinddingPage';
import AdminSideBar from '../../components/AdminComp/AdminSideBar/AdminSideBar';
import OrderDetails from '../../components/AdminComp/OrderDetails/OrderDetails';
import CanceledPage from '../../components/AdminComp/CanceledPage/CanceledPage';
import ProgressPage from '../../components/AdminComp/ProgressPage/ProgressPage';

const Admin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    // Get the current page name from the URL
    const getPageName = (path) => {
        switch (path) {
            case '/admin/pinding':
                return 'الخدمات المعلقة';
            case '/admin/in-progress':
                return 'الخدمات قيد التنفيذ';
            case '/admin/done':
                return 'الخدمات المنتهية';
            case '/admin/canceled':
                return 'الخدمات الملغية';
            case '/admin/orderdetails':
                return 'تفاصيل الطلب';
            default:
                return '';
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        const currentPath = location.pathname; // Get the current path

        if (!token) {
            navigate('/login'); // Redirect to login if not authenticated
        } else if (isInitialLoad && currentPath === '/admin') {
            navigate('/admin/pinding'); // Automatically navigate to /admin/pinding only if on base /admin route
            setIsInitialLoad(false); // Set initial load to false after redirecting
        } else {
            setIsInitialLoad(false); // Ensure initial load is set to false after first render
        }
    }, [navigate, isInitialLoad, location.pathname]);

    // Handle logout functionality
    const handleLogout = () => {
        localStorage.removeItem('token'); // Remove the token
        navigate('/login'); // Redirect to login page
    };

    return (
        <div className='admin-page' dir='rtl'>
            <div className="admin-main-nav">
                <Link to="/" className='G-btn2'>الرئيسية</Link>
                <button className='G-btn2' onClick={handleLogout}>تسجيل خروج</button>
                <h4 style={{ margin: "0 3rem", color: "#fff" }}>{getPageName(location.pathname)}</h4>
            </div>
            <div className='admin-main' dir='ltr'>
                <AdminSideBar />
                <div className='admin-pages'>
                    <div className='admin-pagesS'>
                        <Routes>
                            <Route path="pinding" element={<PinddingPage />} />
                            <Route path="in-progress" element={<ProgressPage />} />
                            <Route path="done" element={<DonePage />} />
                            <Route path="canceled" element={<CanceledPage />} />
                            <Route path="orderdetails" element={<OrderDetails />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Admin;
