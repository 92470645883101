import React from 'react'
import './About.css'
import aboutImg from '../../../assets/sev.jpg'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <div id='About'>
            <div className='about__img'>
                <img src={aboutImg} alt="" />
            </div>
            <div className='about__content'>

                <h1>من نحن </h1>
                <h3>مرحبًا بكم في نور - NOR، الوجهة الأولى لخدمات الصيانة المنزلية المتكاملة. نحن فريق من الخبراء المتخصصين في تقديم حلول شاملة ومبتكرة لصيانة المنازل، بهدف ضمان راحتك وسلامة منزلك. تأسس نور - NOR بناءً على رؤية واضحة لتوفير خدمات صيانة موثوقة وسريعة، تلبي احتياجات كل منزل وتضمن استمرارية تشغيل جميع الأنظمة بكفاءة.
                </h3>
                <Link to="/aboutus" className='G-Link'> المزيد &gt;</Link>

            </div>
        </div>
    )
}

export default About