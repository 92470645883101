import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Loading from '../../Loading/Loading';

const DonePage = () => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedService, setSelectedService] = useState(null);
    const toast = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchServices = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/service/status/finished`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const filteredServices = response.data.data.services.filter(service =>
                    service.fullName && service.phoneNumber
                );
                const sortedServices = filteredServices.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));


                setServices(sortedServices);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);


    const deleteOrder = async () => {
        if (!selectedService) {
            toast.current.show({ severity: 'warn', summary: 'تحذير', detail: 'يرجى تحديد خدمة لحذفها.', life: 3000 });
            return;
        }

        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/admin/service/${selectedService.id}/delete`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setServices(services.filter(service => service.id !== selectedService.id));
            toast.current.show({ severity: 'success', summary: 'نجاح', detail: 'تم حذف الخدمة بنجاح.', life: 3000 });
        } catch (error) {
            console.error("Delete error:", error); // Log the error for debugging
            toast.current.show({ severity: 'error', summary: 'خطأ', detail: 'حدث خطأ أثناء حذف الخدمة.', life: 3000 });
        }
    };

    const confirmDelete = () => {
        if (!selectedService) {
            toast.current.show({ severity: 'warn', summary: 'تحذير', detail: 'يرجى تحديد خدمة لحذفها.', life: 3000 });
            return;
        }

        // Use a simple confirm dialog
        const confirm = window.confirm(`هل أنت متأكد أنك تريد حذف هذه الخدمة؟`);
        if (confirm) {
            deleteOrder(); // Call deleteOrder if confirmed
        } else {
            toast.current.show({ severity: 'info', summary: 'تم الإلغاء', detail: 'تم إلغاء عملية الحذف', life: 3000 });
        }
    };

    const showDetails = () => {
        if (selectedService) {
            navigate(`/admin/orderdetails`, { state: { serviceId: selectedService.id } });
        } else {
            toast.current.show({ severity: 'warn', summary: 'تحذير', detail: 'يرجى تحديد خدمة لعرضها.', life: 3000 });
        }
    };

    if (loading) return <Loading />;


    const header = (
        <div className="table-header" style={{ display: "flex", gap: "4rem", alignItems: "end" }}>
            <div className="table-headerr">
                <h5>الخدمات المنتهية</h5>
                <InputText
                    type="search"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                    style={{ width: '300px' }}
                />
                <Button label="عرض كامل" icon="pi pi-eye" onClick={showDetails} />
                <Button style={{ backgroundColor: 'red', border: "none" }} label="حذف" icon="pi pi-trash" onClick={confirmDelete} />
            </div>
        </div>
    );

    return (
        <div>
            <Toast ref={toast} />
            <DataTable
                value={services}
                paginator
                rows={10}
                dataKey="id"
                loading={loading}
                header={header}
                emptyMessage="لا يوجد خدمات معلقة."
                globalFilter={globalFilter}
                selectionMode="single"
                selection={selectedService}
                onSelectionChange={e => setSelectedService(e.value)}
            >
                <Column selectionMode="single" header="تحديد" headerStyle={{ width: '3rem' }} />
                <Column field="type" header="الخدمة" style={{ minWidth: '9rem', backgroundColor: "#DFE3EE" }} />
                <Column field="fullName" header="أسم العميل" style={{ minWidth: '9rem' }} />
                <Column field="phoneNumber" header="الجوال" style={{ minWidth: '12rem', backgroundColor: "#DFE3EE" }} />
                <Column field="adminNoteOne" header="ملاحظة الإدارة" style={{ minWidth: '9rem', overflow: "hidden", maxHeight: "2rem" }} />

                {services.some(service => service.adminNoteTwo) && (
                    <Column
                        field="adminNoteTwo"
                        header="2ملاحظة الإدارة"
                        style={{ minWidth: '9rem', overflow: "hidden", maxHeight: "2rem", backgroundColor: "#DFE3EE" }}
                    />
                )}
                <Column field="email" header="البريد" style={{ minWidth: '9rem' }} />
                <Column field="periodDate" header="موعد الزيارة" style={{ minWidth: '9rem', backgroundColor: "#DFE3EE" }} />
                <Column field="periodFullTime" header="وقت الزيارة" style={{ minWidth: '9rem' }} />
                <Column field="userNote" header="ملاحظة العميل" style={{ minWidth: '9rem', overflow: "hidden", maxHeight: "2rem" }} />
                <Column field="createdAt" header="تاريخ التقديم" body={(rowData) => new Date(rowData.createdAt).toLocaleString()} style={{ backgroundColor: "#DFE3EE" }} />
            </DataTable>
        </div>
    );
};

export default DonePage;
