import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown'; // Ensure correct import
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Loading from '../../Loading/Loading';

const PendingPage = () => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedService, setSelectedService] = useState(null);
    const [visibleNoteDialog, setVisibleNoteDialog] = useState(false);
    const [adminNoteOne, setAdminNote] = useState('');
    const [serviceStatus, setServiceStatus] = useState('in-progress'); // Default status
    const toast = useRef(null);
    const navigate = useNavigate();

    // Define statusOptions before use
    const statusOptions = [
        { label: 'تنفيذ', value: 'in-progress' },
        { label: 'إلغاء', value: 'cancelled' },
    ];
    const selectedStatusLabel = statusOptions.find(option => option.value === serviceStatus)?.label || 'Unknown';
    useEffect(() => {
        const fetchServices = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/service/status/pending`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const filteredServices = response.data.data.services.filter(service =>
                    service.fullName && service.phoneNumber
                );
                const sortedServices = filteredServices.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setServices(sortedServices);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    const markDone = () => {
        if (selectedService) {
            setAdminNote('');
            setVisibleNoteDialog(true);
        } else {
            toast.current.show({ severity: 'warn', summary: 'تحذير', detail: 'يرجى تحديد خدمة.', life: 3000 });
        }
    };

    const showDetails = () => {
        if (selectedService) {
            navigate(`/admin/orderdetails`, { state: { serviceId: selectedService.id } });
        } else {
            toast.current.show({ severity: 'warn', summary: 'تحذير', detail: 'يرجى تحديد خدمة لعرضها.', life: 3000 });
        }
    };

    const markAsDone = async () => {
        if (selectedService) {
            if (adminNoteOne.length < 2 || adminNoteOne.length > 500) {
                alert("ملاحظ الإدارة يجب ان تكون اكثر من 2 أحرف");
                return;
            }

            const token = localStorage.getItem('token');
            try {
                await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/admin/service/${selectedService.id}/admin-note-one`, {
                    adminNoteOne: adminNoteOne,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                await axios.patch(`${process.env.REACT_APP_API_URL}/api/v1/admin/service-notify/${selectedService.id}/status/${serviceStatus}`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const selectedStatusLabel = statusOptions.find(option => option.value === serviceStatus)?.label || 'Unknown';
                setServices(services.filter(service => service.id !== selectedService.id));
                toast.current.show({ severity: 'success', summary: 'تم', detail: `تم نقل الخدمة إلى ${selectedStatusLabel}`, life: 3000 });

                setAdminNote('');
                setVisibleNoteDialog(false);
            } catch (err) {
                console.error('Error response:', err.response);
                setError(err.message);
            }
        } else {
            toast.current.show({ severity: 'warn', summary: 'تحذير', detail: 'يرجى تحديد خدمة لتغيير حالتها.', life: 3000 });
        }
    };

    if (loading) return <Loading />;
    if (error) return <p>Error: {error}</p>;


    const header = (

        <div className="table-header">
            <div className='table-headerr'>
                <h5>الخدمات المعلقة</h5>
                <InputText
                    type="search"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Search..."
                    style={{ width: '300px' }}
                />
                <Button label="عرض كامل" icon="pi pi-eye" onClick={showDetails} />
            </div>
            <div className='table-headerr'>
                <Dropdown
                    value={serviceStatus}
                    options={statusOptions}
                    onChange={(e) => setServiceStatus(e.value)}
                    placeholder="Select Status"
                />
                <Button label={`نقل: ${selectedStatusLabel}`} icon="pi pi-check" onClick={markDone} /> {/* Add this button */}
            </div>

        </div>
    );


    return (
        <div>
            <Toast ref={toast} />
            <DataTable
                value={services}
                paginator
                rows={10}
                dataKey="id"
                loading={loading}
                header={header}
                emptyMessage="لا يوجد خدمات معلقة."
                globalFilter={globalFilter}
                selectionMode="single"
                selection={selectedService}
                onSelectionChange={e => setSelectedService(e.value)}
            >
                <Column selectionMode="single" header="تحديد" headerStyle={{ width: '3rem' }} />
                <Column field="type" header="الخدمة" style={{ minWidth: '9rem', backgroundColor: "#DFE3EE" }} />
                <Column field="fullName" header="أسم العميل" style={{ minWidth: '9rem' }} />
                <Column field="phoneNumber" header="الجوال" style={{ minWidth: '12rem', backgroundColor: "#DFE3EE" }} />
                <Column field="email" header="البريد" style={{ minWidth: '9rem' }} />
                <Column field="periodDate" header="موعد الزيارة" style={{ minWidth: '9rem', backgroundColor: "#DFE3EE" }} />
                <Column field="periodFullTime" header="وقت الزيارة" style={{ minWidth: '9rem' }} />
                <Column field="userNote" header="ملاحظة العميل" style={{ minWidth: '9rem', overflow: "hidden", maxHeight: "2rem" }} />
                <Column field="createdAt" header="تاريخ التقديم" body={(rowData) => new Date(rowData.createdAt).toLocaleString()} style={{ backgroundColor: "#DFE3EE" }} />
            </DataTable>

            <Dialog header="إضافية ملاحظة" visible={visibleNoteDialog} onHide={() => setVisibleNoteDialog(false)}>
                <textarea
                    value={adminNoteOne}
                    onChange={(e) => setAdminNote(e.target.value)}
                    rows={5}
                    cols={30}
                    placeholder="أدخل الملاحظة هنا..."
                    style={{ width: '100%', height: '100px' }}
                />
                <div style={{ marginTop: '10px', textAlign: 'right' }}>
                    <Button label="إرسال" icon="pi pi-check" onClick={markAsDone} />
                    <Button label="إلغاء" icon="pi pi-times" onClick={() => setVisibleNoteDialog(false)} style={{ marginLeft: '10px' }} />
                </div>
            </Dialog>
        </div>
    );
};

export default PendingPage;
